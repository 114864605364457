import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";

const Page = ({ data }) => {
   const [slideIndex, setSlideIndex] = useState(0);

   const processBgImages = [
      data.processBgDesktop.childImageSharp.fixed,
      {
         ...data.processBgDesktop.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   const url = typeof window !== "undefined" ? window.location.pathname : "";

   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Fiberglass & Composite Parts Manufacturing | Painted Rhino"
            description="We manufacture fiberglass products for a range of applications & industries. Learn about our fiberglass & composite parts manufacturing capabilities."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-6 md:pt-14 pb-20 md:pb-32">
            <div className="container">
               <header className="mb-12">
                  <h1>Fiberglass & Composite Parts Manufacturing</h1>
               </header>

               <div className="mb-12 md:mb-28">
                  <Img fadeIn={false} loading="eager" fluid={data.heroDesktop.childImageSharp.fluid} alt="Fiberglass & Composites" />
               </div>

               <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-20">
                  <div className="md:col-start-1 md:col-span-6">
                     <h2>High-Quality Fiberglass Manufacturing & Composites Production</h2>
                  </div>
                  <div className="md:col-end-13 md:col-span-6">
                     <p className="mb-0">
                        Painted Rhino manufactures fiberglass products for various applications and industries. This includes high-quality composite
                        parts manufacturing. We can produce and supply fiber-reinforced plastic (FRP) parts using open mold or closed mold injection
                        processes. We can also accommodate FRP parts that need assembly in or outside of the mold, with or without additional
                        materials. Learn more about our fiberglass manufacturing molding processes below.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <BackgroundImage fixed={processBgImages} className="w-full pt-22 md:pt-32 pb-18 md:pb-36">
            <div className="container relative">
               <header className="mb-18 md:mb-22 max-w-sm">
                  <h2 className="heading-three">Our Fiberglass Parts Manufacturing Processes</h2>
               </header>

               <ScrollWrapper>
                  <ScrollNavigation>
                     <li>
                        <AnchorLink to={url + "#section-1"} title="Chopper Gun Applications" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-2"} title="VARTM" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-3"} title="Hand Lay-Up Fiberglass" stripHash />
                     </li>
                  </ScrollNavigation>

                  <ScrollContent>
                     <div id="section-1" className="md:bg-white md:pt-14 md:pb-12 md:px-14 mb-12 lg:mb-20">
                        <h3 className="heading-four">Chopper Gun Applications</h3>
                        <p>
                           Chopper guns automatically chop and deposit gun roving strands into a pre-resin mold. Fiberglass rovings are attached to
                           the back of the gun in one continuous piece. Chopper guns cover more surface, making it suitable for producing custom parts
                           in low to medium quantities. Chopper guns are also lightweight, easy to use, and are an economical option for parts like
                           boat hulls, swimming pools, storage tanks, and more. Common material types used in this process include E-glass, Kevlar,
                           and carbon rovings. Mold materials include fiberglass, steel, wood, and other materials.
                        </p>
                        <p className="mb-0">
                           The spray-up process involves the following steps. Gel coats and barrier coats are applied and cured. Once this hardens,
                           the fiber resin mixture is added onto the mold. The spray gun then chops the rovings into short pieces at predetermined
                           lengths. After the material is sprayed, brushes or rollers remove trapped air to ensure a smooth product. The materials are
                           cured, left to cool, and finished with trimming to remove excess fiberglass.
                        </p>
                     </div>

                     <div id="section-2" className="md:bg-white md:pt-14 md:pb-12 md:px-14 mb-12 lg:mb-20">
                        <h3 className="heading-four">Vacuum Assisted Resin Transfer Molding</h3>
                        <p className="mb-0">
                           (VARTM) or Vacuum Injected Molding (VIM) is a closed <a href="https://www.paintedrhino.com/tooling-mold-making-metal-work/" className="text-[#006ee8]">mold process</a> used in producing large scale fiber reinforced polymer
                           (FRP) parts. Materials used in this process in addition to standard E glass include high performance fibers like carbon
                           fiber (for high end parts) and low viscosity resins. The mold is covered by a vacuum bag (or for higher production a B side
                           mold) and the vacuum helps with the flow of resin infused into the fiber layup. After infusion, the vacuum’s low pressure
                           reinforces the resin and removes air from the preform. Aside from aerospace-grade parts, VARTM is also used to produce a
                           variety of parts for wind energy, transportation, and infrastructure to name a few.
                        </p>
                     </div>

                     <div id="section-3" className="md:bg-white md:pt-14 md:pb-12 md:px-14">
                        <h3 className="heading-four">Hand Lay-Up Fiberglass</h3>
                        <p className="mb-0">
                           This process is similar to spray up but the fiber reinforcements are placed by hand instead of automatically. Gel coat is
                           still applied (either by spraying or brushing) and the laminate will be cut to desired size. The fiber reinforcement is
                           then wet out using polyester, vinylester, or epoxy resin. A roller or squeegee helps work the wet fiber into the mold. The
                           hand lay-up process is ideal for low cost tooling, low volumes, and high quality products.
                        </p>
                     </div>
                  </ScrollContent>
               </ScrollWrapper>
            </div>
         </BackgroundImage>

         <section className="py-20 md:py-32">
            <div className="container">
               <div className="grid md:grid-cols-2 gap-2 md:gap-4">
                  <div className="grid grid-rows-2 grid-cols-10 gap-2 md:gap-4">
                     <div className="col-start-1 col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(0)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail1.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-end-11 col-span-4 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(1)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail2.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-start-1 col-span-4 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(2)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail3.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-end-11 col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(3)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail4.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
                  <div className="grid grid-rows-2 grid-cols-10 grid-flow-col gap-2 md:gap-4">
                     <div className="col-span-4 row-span-2">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(4)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail5.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(5)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail6.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="row-span-1 col-span-6">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(6)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail7.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <TestimonialBackgroundImage />

         <WhyUs
            className="pt-22 md:pt-32 pb-20 md:pb-28"
            contentAlignment="md:text-center md:mx-auto"
            headerHeading="The Painted Rhino Difference."
         />

         <CTABackgroundImage />

         <ModalGallery slideIndex={slideIndex}>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox1.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox2.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox3.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox4.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox5.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox6.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox7.childImageSharp.fluid} alt="" />
            </div>
         </ModalGallery>
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Fiberglass _ Composites_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Fiberglass _ Composites_Twitter.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "services/fiberglass-composites.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2400) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      processBgDesktop: file(relativePath: { eq: "services/process-bg-desktop.jpg" }) {
         childImageSharp {
            fixed(width: 2880, height: 1432) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      galleryThumbnail1: file(
         relativePath: {
            eq: "services/Fiberglass-Composites/Gallery/Thumbnails/star-wars-rise-of-the-resistance-full-disney-world-queue-pho_37ny.jpg"
         }
      ) {
         childImageSharp {
            fluid(maxWidth: 355) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox1: file(
         relativePath: { eq: "services/Fiberglass-Composites/Gallery/Lightbox/star-wars-rise-of-the-resistance-full-disney-world-queue-pho_37ny.jpg" }
      ) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail2: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Thumbnails/one thousand steps.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 221) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox2: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Lightbox/one thousand steps.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail3: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Thumbnails/ty fighter _ trooper.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox3: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Lightbox/ty fighter _ trooper.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail4: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Thumbnails/PointMugu-surf-sideview_1800x.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox4: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Lightbox/PointMugu-surf-sideview_1800x.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail5: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Thumbnails/lax light pole.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox5: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Lightbox/lax light pole.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail6: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Thumbnails/a22f_12.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox6: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Lightbox/a22f_12.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail7: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Thumbnails/IMG_0473.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox7: file(relativePath: { eq: "services/Fiberglass-Composites/Gallery/Lightbox/IMG_0473.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;
